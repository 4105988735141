import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Stack, Typography } from '@mui/material'
import CustomTooltip from '../../../../components/common/CustomTooltip';
import ButtonSpinner from '../../../../components/common/ButtonSpinner'
import { BASEURL, checktoken, headers } from "../../../../utils/helper"
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { GET_SCRAPE_REQ } from "../../../../redux/saga/sagaConst"
import { Form, Col, Tab, Nav, InputGroup } from 'react-bootstrap'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import Toaster from '../../../../components/common/Toaster'
import { useDispatch, useSelector } from "react-redux"
import ListAltIcon from '@mui/icons-material/ListAlt'
import { Fragment, useEffect, useState } from 'react'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import { useNavigate, Link } from 'react-router-dom'
import { toast } from "react-toastify"
import { Cookies } from 'react-cookie'
import axios from 'axios'

const ScrapeReader = () => {
	const cookies = new Cookies()
	const navigate = useNavigate()
	const dispatch = useDispatch();
	const [name, setName] = useState('')
	const [links, setLinks] = useState([{}])
	const [open, setOpen] = useState(false)
	const [fileData, setFileData] = useState('')
	const [spinner, setSpinner] = useState(false)
	const user = useSelector((state) => state.AuthSlice)

	const handleChange = (e) => {
		setFileData(e.target.files)
	}

	const handleRemove = (i) => {
		let arr = Object.keys(fileData).map((item) => item !== i && fileData[item])
		arr = arr.filter(item => item !== false && item)
		setFileData(arr)
	}

	const handleChangeInputValue = (e, i) => {
		links[i].value = e.target.value
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		const formData = new FormData()
		for (let x of Object.keys(fileData)) {
			formData.append('file', fileData[x])
		}
		formData.append('name', name)
		setSpinner(true)
		axios.post(`${BASEURL}/user/train/file`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Authorization': `Bearer ${cookies.get('auth')}`,
			},
		}).then((res) => {
			if (res?.data?.success === true) {
				dispatch({ type: GET_SCRAPE_REQ, payload: "" });
				toast.success(res?.data?.msg)
				navigate('/user/chat/scrap-list')
			}
			setSpinner(false)
		}).catch((err) => {
			checktoken(err)
			setSpinner(false)
			if (err?.response?.data?.success === false) {
				toast.error(err.response.data.error);
			}
		})
	}

	const handleLinkSubmit = (e) => {
		e.preventDefault()
		setSpinner(true)
		const arr = links.filter((item) => item.value)
		axios.post(`${BASEURL}/user/train/file`, { links: arr }, {
			headers: headers(),
		}).then((res) => {
			if (res?.data?.success === true) {
				dispatch({ type: GET_SCRAPE_REQ, payload: "" });
				toast.success(res?.data?.msg)
				navigate('/user/chat/scrap-list')
			}
			setSpinner(false)
		}).catch((err) => {
			checktoken(err)
			setSpinner(false)
			if (err?.response?.data?.success === false) {
				toast.error(err.response.data.error);
			}
		})
	}

	useEffect(() => {

		// eslint-disable-next-line
	}, [])

	return (
		<div id="Reader" className="h-100">
			<Toaster />
			<div className="p-3">
				<div>
					<div className="flex items-start justify-between relative">
						<Typography variant="h5" className="text-xl ff-lato font-medium text-primary-light m-0">Sources</Typography>
						<Stack direction="row" spacing={1}>
							<CustomTooltip
							//  title="لیست فایل های من"
							 
							 arrow placement={'bottom'} content={<Button startIcon={<ListAltIcon />} LinkComponent={Link} to="/user/chat/scrap-list" className="primary-blue-btn ff-lato">My listed Files</Button>} />
							<Box className="about-info-container">
								<CustomTooltip 
								
								// title="اینخواندن محتوا یا لینک اپلود شده"
								
								arrow placement={'bottom'} content={<Button startIcon={<DocumentScannerIcon />} LinkComponent={Link} to="/user/chat/scrap-reader" className="primary-blue-btn ff-lato">reader</Button>} />
							</Box>
						</Stack>
					</div>
					<p>Upload documents or add links to your knowledge base or website to train ExecutiveGPT on your own data.</p>
				</div>
				<div className="scraping-tab p-4 bg-light rounded">
					<Tab.Container id="left-tabs-example" defaultActiveKey="files">
						<Nav variant="pills" className="flex-row scraping-tab-ul p-1 rounded">
							<Nav.Item>
								<Nav.Link eventKey="files">files</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="links">links</Nav.Link>
							</Nav.Item>
						</Nav>
						<Tab.Content>
							<Tab.Pane eventKey="files" className="pt-3">
								<Form onSubmit={(e) => { (user?.currentplan && (user?.currentplan?.plankey === 'execs_pro_monthly' || user?.currentplan?.plankey === 'execs_pro_semianual' || user?.currentplan?.plankey === 'execs_pro_yearly' || user?.currentplan?.plankey === 'team_member_monthly' || user?.currentplan?.plankey === 'team_member_semi' || user?.currentplan?.plankey === 'team_member_annual')) ? handleSubmit(e) : e.preventDefault() }}>
									<div className="rounded upload-doc p-4 position-relative text-center">
										<div className="d-block image-up-icon mb-2"><NoteAddIcon /></div>
										<h6>Click to upload a file or drag and drop it here</h6>
										<p className="m-0 tab-small-text">Up to 100MB in size. PDF, TXT, DOCX</p>
										<input type="file" accept=".pdf, .doc, .docx, .text" className="position-absolute start-0 end-0 top-0 bottom-0 opacity-0 w-100" onChange={(e) => { handleChange(e) }} />
									</div>
									<Col md={6} className="m-auto">
										<Form.Group className="input-field mt-3">
											<Form.Control type="text" placeholder="Give a name to your file" className="custom-field"
												value={name} onChange={(e) => { setName(e.target.value) }} />
										</Form.Group>
										{(fileData.length > 0) &&
											<Nav as="ul" className="mt-3 files-ul">
												{Object.keys(fileData).map((i) => {
													return (
														<Nav.Item as="li" className="d-flex flex-grow-1 align-items-center" key={i}>
															<InsertDriveFileIcon />
															<Nav.Link>{fileData[i].name}</Nav.Link>
															<IconButton
																className="ms-auto"
																size="small"
																edge="start"
																color="inherit"
																onClick={() => { handleRemove(i); }}
															>
																<DeleteForeverIcon className="text-danger" />
															</IconButton>
														</Nav.Item>
													)
												})}
											</Nav>
										}
										<div className="my-3 text-center">
											{(fileData.length === 0) ? <Button type="button" className="bg-primary-light opacity-80 text-white" disabled sx={{ borderRadius: '10px' }}>upload to train</Button> : <Button type="submit" className="bg-primary-light hover:bg-primary-main text-white" sx={{ borderRadius: '10px' }} onClick={handleSubmit}>upload to train {spinner === true && <ButtonSpinner />}</Button>}
										</div>
									</Col>
								</Form>
							</Tab.Pane>

							<Tab.Pane eventKey="links" className="pt-3">
								<Col md={4} className="m-auto">
									<Form onSubmit={(e) => { user?.currentplan ? handleLinkSubmit(e) : e.preventDefault() }}>
										<Nav as="ul" className="mt-3 files-ul">
											{links.map((item, i) => (
												<Fragment key={i}>
													<Nav.Item as="li" className="d-flex flex-grow-1 align-items-center mb-1" >
														<InputGroup className="me-2">
															<InputGroup.Text>
																<LinkRoundedIcon />
															</InputGroup.Text>
															<Form.Control
																defaultValue={item?.value}
																onChange={(e) => handleChangeInputValue(e, i)}
																placeholder="Add link"
															/>
														</InputGroup>
														{/* {i === 0 ?
															<IconButton
																className="ms-auto"
																size="small"
																edge="start"
																color="inherit"
																onClick={() => addInput(i)}
															>
																<AddCircleOutlineRoundedIcon />
															</IconButton> :
															<IconButton
																className="ms-auto"
																size="small"
																edge="start"
																color="inherit"
																onClick={() => { removeLink(i) }}
															>
																<DeleteForeverIcon className="text-danger" />
															</IconButton>
														} */}
													</Nav.Item>
													<Form.Text id="passwordHelpBlock" muted>Link must be started with https://</Form.Text>
												</Fragment>
											))
											}
										</Nav>
										<div className="my-3 text-center">
											{(links.length === 0) ? <Button type="button" className="bg-primary-light opacity-80 text-white" disabled sx={{ borderRadius: '10px' }}>upload to train</Button> : <Button type="submit" className="bg-primary-light hover:bg-primary-main text-white" sx={{ borderRadius: '10px' }}>upload to train {spinner === true && <ButtonSpinner />}</Button>}
										</div>
									</Form>
								</Col>
							</Tab.Pane>
						</Tab.Content>
					</Tab.Container>
				</div>
			</div>

			<Dialog
				open={open}
				maxWidth={'xs'}
				fullWidth
			>
				<DialogContent>Upload a Doc or add a Link to start with exciting feature of Doc Reader </DialogContent>
				<DialogActions className="border-solid border-t-[1px] border-border">
					<Button onClick={() => setOpen(false)} size="small" className="bg-primary-light hover:bg-primary-main text-white">ok</Button>
				</DialogActions>
			</Dialog>
		</div >
	)
}

export default ScrapeReader;