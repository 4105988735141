import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import { checktoken, BASEURL, headers } from "../../utils/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SwitchVideoIcon from "@mui/icons-material/SwitchVideo";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import TranslateIcon from "@mui/icons-material/Translate";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Logo from "../../assets/images/logo-white.png";
import ImageIcon from "@mui/icons-material/Image";
import CustomTooltip from "../common/CustomTooltip";
import InboxIcon from "@mui/icons-material/Inbox";
import AnimateHeight from "react-animate-height";
import { Box, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { icons } from "../../assets/js/icons";
import { useEffect, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import axios from "axios";
import { PopupContext } from "../../context/PopupContext";

const {
  MuiUpArrow,
  MuiDownArrow,
  MuiResearch,
  MuiArticle,
  MuiHistory,
  MuiDocumentScanner,
  Insights,
} = icons;

function ChatSidebar({ closeFun }) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.AuthSlice);
  const navigate = useNavigate();
  const { setpopupState } = useContext(PopupContext);
  const { pathname } = useLocation();
  const [input, setInput] = useState("");
  const [height2, setHeight2] = useState(0);
  const [prompts, setPompts] = useState([]);

  const [currentPlanPackages, setCurrentPlanPackages] = useState([]);
  const [currentPlansStatus, setCurrentPlansStatus] = useState([]);
  // console.log("currentplan.../", currentplan);
  // console.log("currentPlanPackages.../", currentPlanPackages);
  // console.log("currentPlansStatus.../", currentPlansStatus);

  // console.log("business insights prompts.../", prompts);

  const FeaturedContent = () => {
    axios
      .get(`${BASEURL}/user/featured/content/list`, {
        headers: headers(),
      })
      .then((res) => {
        if (res.data.success === true) {
          setPompts(res.data.data);
        }
      })
      .catch((err) => {
        checktoken(err);
      });
  };

  const SearchPromptForUser = () => {
    if (input === "") {
      setPompts([]);
      FeaturedContent();
    } else {
      setPompts([]);
      axios
        .get(`${BASEURL}/user/search/featured/content?text=${input}`, {
          headers: headers(),
        })
        .then((res) => {
          if (res.data.success === true) {
            setPompts(res.data.data);
          }
        })
        .catch((err) => {
          checktoken(err);
        });
    }
  };

  const checkPlan = () => {
    if (user?.currentplan?.[0]) {
      if (
        user?.currentplan?.[0]?.plankey === "pro_monthly" ||
        user?.currentplan?.[0]?.plankey === "pro_semianual"
      ) {
        FeaturedContent();
      }
    }
  };

  useEffect(() => {
    checkPlan();
  }, [navigate]);

  useEffect(() => {
    if (user?.currentplan) {
      const plans = [];
      let statuses = {};
      user?.currentplan?.map((pack) => {
        statuses = { ...statuses, [pack?.plankey]: pack?.status };
        plans.push(pack?.plankey);
      });
      setCurrentPlansStatus(statuses);
      setCurrentPlanPackages(plans);
    }
  }, [user]);

  return (
    <Box id="ChatSidebar" style={{ width: "250px" }} className="h-full">
      <div className="logo-sec px-4 sticky-sm-top border-bottom mb-2">
        <div className="text-white d-flex align-items-center">
          <img className="m-auto" src={Logo} alt="img" />
          <IconButton className="text-white flex md:hidden" onClick={closeFun}>
            {<icons.MuiLogout className="rotate-180" />}
          </IconButton>
        </div>
      </div>
      {user?.currentplan ? (
        <div className="body-sec px-3 d-flex flex-column gap-2">
          <Link
            to={`/user/chat/outside`}
            className={`menu-item relative first-step p-2 rounded ${
              pathname === "/user/chat/outside" && "active"
            }`}
          >
            <span>{MuiResearch}</span>
            <span>{t("executive")}</span>
          </Link>
          {user?.currentplan &&
          (currentPlanPackages?.includes("pro_monthly") ||
            currentPlanPackages?.includes("pro_semianual") ||
            currentPlanPackages?.includes("ar_monthly") ||
            currentPlanPackages?.includes("ar_semianual")) ? (
            <CustomTooltip
              // title="Essay writer"
              arrow
              placement="right"
              content={
                <Link
                  className={`menu-item p-2 rounded second-step ${
                    pathname === "/user/chat/articles" && "active"
                  }`}
                  to="articles"
                >
                  <span>{MuiArticle}</span>
                  <span>{t("article_writer")}</span>
                </Link>
              }
            />
          ) : (
            <CustomTooltip
              // title="Essay writer"
              arrow
              placement="right"
              content={
                <Link
                  onClick={() => {
                    setpopupState(true);
                  }}
                  className={`menu-item relative third-step p-2 rounded`}
                >
                  <span>{MuiArticle}</span>
                  <span>{t("article_writer")}</span>
                  <span className="tag-text">{t("pro")}</span>
                </Link>
              }
            />
          )}
          {user?.currentplan &&
          (currentPlanPackages?.includes("pro_monthly") ||
            currentPlanPackages?.includes("pro_semianual") ||
            currentPlanPackages?.includes("dr_monthly") ||
            currentPlanPackages?.includes("dr_semianual")) ? (
            <CustomTooltip
              title="Upload the book, article and link in any language and ask about its content in any language"
              arrow
              placement="right"
              content={
                <Link
                  to="/user/chat/scrapping"
                  className={`menu-item relative third-step p-2 rounded ${
                    (pathname === "/user/chat/scrapping" ||
                      pathname === "/user/chat/scrap-reader") &&
                    "active"
                  }`}
                >
                  <span>{<MuiDocumentScanner />}</span>
                  <span>{t("doc_reader")}</span>
                </Link>
              }
            />
          ) : (
            <CustomTooltip
              title="Upload the book, article and link in any language and ask about its content in any language"
              arrow
              placement="right"
              content={
                <Link
                  onClick={() => {
                    setpopupState(true);
                  }}
                  className={`menu-item relative third-step p-2 rounded`}
                >
                  <span>{<MuiDocumentScanner />}</span>
                  <span>{t("doc_reader")}</span>
                  <span className="tag-text">{t("pro")}</span>
                </Link>
              }
            />
          )}

          {user?.currentplan?.[0] &&
          (currentPlanPackages?.includes("pro_monthly") ||
            currentPlanPackages?.includes("pro_semianual") ||
            currentPlanPackages?.includes("sm_monthly") ||
            currentPlanPackages?.includes("sm_semianual")) ? (
            <CustomTooltip
              title="caption and hashtag generator"
              arrow
              placement="right"
              content={
                <Link
                  className={`menu-item p-2 rounded fourth-step ${
                    pathname === "/user/chat/social-media" && "active"
                  }`}
                  to="/user/chat/social-media"
                >
                  <span>
                    <ConnectWithoutContactIcon />
                  </span>
                  <span>{t("social_media_m")}</span>
                </Link>
              }
            />
          ) : (
            <CustomTooltip
              title="caption and hashtag generator"
              arrow
              placement="right"
              content={
                <Link
                  onClick={() => {
                    setpopupState(true);
                  }}
                  className={`menu-item relative third-step p-2 rounded`}
                >
                  <span>
                    <ConnectWithoutContactIcon />
                  </span>
                  <span>{t("social_media_m")}</span>
                  <span className="tag-text">{t("pro")}</span>
                </Link>
              }
            />
          )}

          {user?.currentplan?.[0] &&
          (currentPlanPackages?.includes("pro_monthly") ||
            currentPlanPackages?.includes("pro_semianual") ||
            currentPlanPackages?.includes("basic_monthly") ||
            currentPlanPackages?.includes("basic_semianual")) ? (
            <>
              <CustomTooltip
                title="Translator of 80 languages"
                arrow
                placement="right"
                content={
                  <Link
                    className={`menu-item p-2 rounded fifth-step ${
                      pathname === "/user/chat/translator" && "active"
                    }`}
                    to="/user/chat/translator"
                  >
                    <span>
                      <TranslateIcon />
                    </span>
                    <span>{t("translator")}</span>
                  </Link>
                }
              />
            </>
          ) : (
            <>
              <CustomTooltip
                title="Translator of 80 languages"
                arrow
                placement="right"
                content={
                  <Link
                    className={`menu-item p-2 rounded fifth-step ${
                      pathname === "/user/chat/translator" && "active"
                    }`}
                    onClick={() => {
                      setpopupState(true);
                    }}
                    // to="/user/chat/translator"
                  >
                    <span>
                      <TranslateIcon />
                    </span>
                    <span>{t("translator")}</span>
                    <span className="tag-text">{t("pro")}</span>
                  </Link>
                }
              />
            </>
          )}

          {user?.currentplan?.[0] &&
          (currentPlanPackages?.includes("pro_monthly") ||
            currentPlanPackages?.includes("pro_semianual") ||
            currentPlanPackages?.includes("basic_monthly") ||
            currentPlanPackages?.includes("basic_semianual")) ? (
            <>
              <CustomTooltip
                // title="Image Transcription"
                arrow
                placement="right"
                content={
                  <Link
                    className={`menu-item p-2 rounded fifth-step ${
                      pathname === "/user/chat/TranscribeImage" && "active"
                    }`}
                    to="/user/chat/TranscribeImage"
                  >
                    <span>
                      <ImageIcon />
                    </span>
                    <span>{t("image_transcript")}</span>
                  </Link>
                }
              />
            </>
          ) : (
            <>
              <CustomTooltip
                // title="Image Transcription"
                arrow
                placement="right"
                content={
                  <Link
                    className={`menu-item p-2 rounded fifth-step ${
                      pathname === "/user/chat/TranscribeImage" && "active"
                    }`}
                    onClick={() => {
                      setpopupState(true);
                    }}
                    // to="/user/chat/TranscribeImage"
                  >
                    <span>
                      <ImageIcon />
                    </span>
                    <span>{t("image_transcript")}</span>
                    <span className="tag-text">{t("pro")}</span>
                  </Link>
                }
              />
            </>
          )}

          {user?.currentplan?.[0] &&
          (currentPlanPackages?.includes("pro_monthly") ||
            currentPlanPackages?.includes("pro_semianual") ||
            currentPlanPackages?.includes("basic_monthly") ||
            currentPlanPackages?.includes("basic_semianual")) ? (
            <>
              <CustomTooltip
                title="Professional text reconstruction"
                arrow
                placement="right"
                content={
                  <Link
                    className={`menu-item p-2 rounded sixth-step ${
                      pathname === "/user/chat/rephrase" && "active"
                    }`}
                    to="/user/chat/rephrase"
                  >
                    <span>
                      <BorderColorIcon />
                    </span>
                    <span>{t("rephrase")}</span>
                  </Link>
                }
              />
            </>
          ) : (
            <>
              <CustomTooltip
                title="Professional text reconstruction"
                arrow
                placement="right"
                content={
                  <Link
                    className={`menu-item p-2 rounded sixth-step ${
                      pathname === "/user/chat/rephrase" && "active"
                    }`}
                    // to="/user/chat/rephrase"
                    onClick={() => {
                      setpopupState(true);
                    }}
                  >
                    <span>
                      <BorderColorIcon />
                    </span>
                    <span>{t("rephrase")}</span>
                    <span className="tag-text">{t("pro")}</span>
                  </Link>
                }
              />
            </>
          )}

          {user?.currentplan &&
          (currentPlanPackages?.includes("pro_monthly") ||
            currentPlanPackages?.includes("pro_semianual") ||
            currentPlanPackages?.includes("email_monthly") ||
            currentPlanPackages?.includes("email_semianual")) ? (
            <CustomTooltip
              // title="Generate email text"
              arrow
              placement="right"
              content={
                <Link
                  className={`menu-item p-2 rounded seventh-step ${
                    pathname === "/user/chat/emails" && "active"
                  }`}
                  to="/user/chat/emails"
                >
                  <span>
                    {" "}
                    <InboxIcon />
                  </span>
                  <span>{t("email_temp")}</span>
                </Link>
              }
            />
          ) : (
            <CustomTooltip
              // title="Generate email text"
              arrow
              placement="right"
              content={
                <Link
                  onClick={() => {
                    setpopupState(true);
                  }}
                  className={`menu-item relative third-step p-2 rounded`}
                >
                  <span>
                    {" "}
                    <InboxIcon />
                  </span>
                  <span>{t("email_temp")}</span>
                  <span className="tag-text">{t("pro")}</span>
                </Link>
              }
            />
          )}
          {user?.currentplan &&
          (currentPlanPackages?.includes("pro_monthly") ||
            currentPlanPackages?.includes("pro_semianual") ||
            currentPlanPackages?.includes("hr_monthly") ||
            currentPlanPackages?.includes("hr_semianual")) ? (
            <CustomTooltip
              title="Upload, search and summarize resumes"
              arrow
              placement="right"
              content={
                <Link
                  className={`menu-item p-2 rounded eight-step ${
                    pathname === "/user/chat/hruploads" && "active"
                  }`}
                  to="/user/chat/hruploads"
                >
                  <span>
                    <UploadFileIcon />
                  </span>
                  <span>{t("hr_uploads")}</span>
                </Link>
              }
            />
          ) : (
            <CustomTooltip
              title="Upload, search and summarize resumes"
              arrow
              placement="right"
              content={
                <Link
                  onClick={() => {
                    setpopupState(true);
                  }}
                  className={`menu-item relative third-step p-2 rounded`}
                >
                  <span>{<UploadFileIcon />}</span>
                  <span>{t("hr_uploads")}</span>
                  <span className="tag-text">{t("pro")}</span>
                </Link>
              }
            />
          )}
          {user?.currentplan &&
          (currentPlanPackages?.includes("pro_monthly") ||
            currentPlanPackages?.includes("pro_semianual") ||
            currentPlanPackages?.includes("ig_monthly") ||
            currentPlanPackages?.includes("ig_semianual")) ? (
            <CustomTooltip
              title="Convert text to image"
              arrow
              placement="right"
              content={
                <Link
                  className={`menu-item p-2 rounded nine-step ${
                    pathname === "/user/chat/image-generator" && "active"
                  }`}
                  to="/user/chat/image-generator"
                >
                  <span>
                    <BurstModeIcon />
                  </span>
                  <span>{t("img_generator")}</span>
                </Link>
              }
            />
          ) : (
            <CustomTooltip
              title="Convert text to image"
              arrow
              placement="right"
              content={
                <Link
                  onClick={() => {
                    setpopupState(true);
                  }}
                  className={`menu-item relative third-step p-2 rounded`}
                >
                  <span>{<BurstModeIcon />}</span>
                  <span>{t("img_generator")}</span>
                  <span className="tag-text">{t("pro")}</span>
                </Link>
              }
            />
          )}
          {user?.currentplan[0] &&
          (currentPlanPackages?.includes("pro_monthly") ||
            currentPlanPackages?.includes("pro_semianual") ||
            currentPlanPackages?.includes("ttv_monthly") ||
            currentPlanPackages?.includes("ttv_semianual")) ? (
            <CustomTooltip
              // title="Text to video"
              arrow
              placement="right"
              content={
                <Link
                  className={`menu-item p-2 rounded second-step ${
                    pathname === "/user/chat/TextToVideo" && "active"
                  }`}
                  to="/user/chat/TextToVideo"
                  // className={`menu-item p-2 rounded nine-step`}
                >
                  <span>
                    <SwitchVideoIcon />
                  </span>
                  <span>{t("text_video")}</span>
                  {/* <span className="tag-text">{t("coming_soon")}</span> */}
                </Link>
              }
            />
          ) : (
            <>
              <CustomTooltip
                // title="Text to video"
                arrow
                placement="right"
                content={
                  <Link
                    className={`menu-item p-2 rounded second-step ${
                      pathname === "/user/chat/TextToVideo" && "active"
                    }`}
                    // className={`menu-item p-2 rounded nine-step`}
                    // to="/user/chat/TextToVideo"

                    // onClick={() => {

                    //   setpopupState(true);
                    // }}
                  >
                    <span>
                      <SwitchVideoIcon />
                    </span>
                    <span>{t("text_video")}</span>
                    {/* <span className="tag-text">{t("coming_soon")}</span> */}
                    <span className="tag-text">{t("pro")}</span>
                  </Link>
                }
              />
            </>
          )}

          <CustomTooltip
            // title="Text to Voice"
            arrow
            placement="right"
            content={
              <Link className={`menu-item p-2 rounded nine-step`} to="">
                <span>
                  <GraphicEqIcon />
                </span>
                <span>{t("text_voice")}</span>
                <span className="tag-text">{t("coming_soon")}</span>
              </Link>
            }
          />

          <CustomTooltip
            title="Sorted history of all your works"
            arrow
            placement="right"
            content={
              <Link
                className={`menu-item p-2 rounded ten-step ${
                  pathname === "/user/chat/projects" && "active"
                }`}
                to="projects"
              >
                <span>{MuiHistory}</span>
                <span>{t("project_pool")}</span>
              </Link>
            }
          />

          {user?.currentplan &&
          (currentPlanPackages?.includes("pro_monthly") ||
            currentPlanPackages?.includes("pro_semianual")) ? (
            <div className="seventh-step" onMouseLeave={() => setHeight2(0)}>
              <Button
                aria-expanded={height2 !== 0}
                aria-controls="example-panel"
                className="accor-btn menu-item p-2 rounded w-100"
                onClick={() => setHeight2(height2 === 0 ? "auto" : 0)}
              >
                <div className="d-flex w-100w-100">
                  <span>{Insights}</span>
                  <span className="col flex-grow-1">{t("business_in")}</span>
                  {height2 === 0 ? (
                    <span>{MuiDownArrow}</span>
                  ) : (
                    <span>{MuiUpArrow}</span>
                  )}
                </div>
              </Button>
              <AnimateHeight
                id="example-panel"
                duration={500}
                className="accor mb-3"
                height={height2}
              >
                <div className="d-flex">
                  <input
                    type="text"
                    placeholder="Search prompts"
                    className="form-control"
                    onChange={(e) => {
                      setInput(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      SearchPromptForUser();
                    }}
                  />
                </div>
                {prompts.length > 0 &&
                  prompts.map((item, index) => (
                    <Link
                      key={index}
                      to={`/user/chat/prompt/${item._id}`}
                      className={`menu-item w-100 p-2 rounded-0 border-bottom `}
                    >
                      <span>{MuiArticle}</span>
                      <span>{item.title}</span>
                    </Link>
                  ))}
              </AnimateHeight>
            </div>
          ) : (
            <Link
              onClick={() => {
                setpopupState(true);
              }}
              className={`menu-item p-2 rounded ten-step`}
            >
              <span>{MuiHistory}</span>
              <span>{t("business_in")}</span>
              <span className="tag-text">{t("pro")}</span>
            </Link>
          )}
        </div>
      ) : (
        <div className="body-sec px-3 d-flex flex-column gap-2">
          <Link className={`menu-item p-2 rounded ten-step`} to="/pricing">
            <span>{MuiResearch}</span>
            <span>{t("executive")}</span>
          </Link>
          <CustomTooltip
            title="Essay writer"
            arrow
            placement="right"
            content={
              <Link className={`menu-item p-2 rounded ten-step`} to="/pricing">
                <span>{MuiArticle}</span>
                <span>{t("article_writer")}</span>
              </Link>
            }
          />
          <CustomTooltip
            title="Upload the book, article and link in any language and ask about its content in Farsi"
            arrow
            placement="right"
            content={
              <Link className={`menu-item p-2 rounded ten-step`} to="/pricing">
                <span>{<MuiDocumentScanner />}</span>
                <span>{t("doc_reader")}</span>
              </Link>
            }
          />
          <CustomTooltip
            title="Social Media"
            arrow
            placement="right"
            content={
              <Link className={`menu-item p-2 rounded ten-step`} to="/pricing">
                <span>
                  <ConnectWithoutContactIcon />
                </span>
                <span>{t("social_media")}</span>
              </Link>
            }
          />
          <CustomTooltip
            title="Translator of 80 languages"
            arrow
            placement="right"
            content={
              <Link className={`menu-item p-2 rounded ten-step`} to="/pricing">
                <span>
                  <TranslateIcon />
                </span>
                <span>{t("translator")}</span>
              </Link>
            }
          />
          <CustomTooltip
            title="Professional text reconstruction"
            arrow
            placement="right"
            content={
              <Link className={`menu-item p-2 rounded ten-step`} to="/pricing">
                <span>
                  <BorderColorIcon />
                </span>
                <span>{t("rephrase")}</span>
              </Link>
            }
          />
          <CustomTooltip
            // title="Generate email text"
            arrow
            placement="right"
            content={
              <Link className={`menu-item p-2 rounded ten-step`} to="/pricing">
                <span>
                  <InboxIcon />
                </span>
                <span>{t("email_temp")}</span>
              </Link>
            }
          />
          <CustomTooltip
            title="Upload, search and summarize resumes"
            arrow
            placement="right"
            content={
              <Link className={`menu-item p-2 rounded ten-step`} to="/pricing">
                <span>
                  <UploadFileIcon />
                </span>
                <span>{t("hr_uploads")}</span>
              </Link>
            }
          />
          <CustomTooltip
            title="Convert text to image"
            arrow
            placement="right"
            content={
              <Link className={`menu-item p-2 rounded ten-step`} to="/pricing">
                <span>
                  <BurstModeIcon />
                </span>
                <span>{t("img_generator")}</span>
              </Link>
            }
          />
          <CustomTooltip
            title="Text to Video"
            arrow
            placement="right"
            content={
              <Link
                className={`menu-item p-2 rounded nine-step`}
                to="/user/chat/TextToVideo"
              >
                <span>
                  <SwitchVideoIcon />
                </span>
                <span>{t("text_video")}</span>
                {/* <span className="tag-text">{t("coming_soon")}</span> */}
              </Link>
            }
          />
          <CustomTooltip
            title="Text to Voice"
            arrow
            placement="right"
            content={
              <Link className={`menu-item p-2 rounded nine-step`} to="/pricing">
                <span>
                  <GraphicEqIcon />
                </span>
                <span>{t("text_voice")}</span>
                <span className="tag-text">{t("coming_soon")}</span>
              </Link>
            }
          />
          <CustomTooltip
            title="Sorted history of all your works"
            arrow
            placement="right"
            content={
              <Link className={`menu-item p-2 rounded ten-step`} to="/pricing">
                <span>{MuiHistory}</span>
                <span>{t("project_pool")}</span>
              </Link>
            }
          />

          {/* <Link className={`menu-item p-2 rounded ten-step`} to="/pricing">
            <span>{MuiHistory}</span>
            <span>{t("project_pool")}</span>
          </Link> */}
          <Link className={`menu-item p-2 rounded ten-step`} to="/pricing">
            <span>{MuiHistory}</span>
            <span>{t("business_in")}</span>
          </Link>
        </div>
      )}
    </Box>
  );
}

export default ChatSidebar;
