import React, {
  Suspense,
  lazy,
  useRef,
  useState,
  useEffect,
  useMemo,
  Fragment,
} from "react";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import { ChatPromptRequest } from "../../../redux/slices/ChatPromptSlice";
import { WordCountRequest } from "../../../redux/slices/CounterSlice";
import { BASEURL, checktoken, headers } from "../../../utils/helper";
import ButtonSpinner from "../../../components/common/ButtonSpinner";
import CustomTooltip from "../../../components/common/CustomTooltip";
import { removeMessage } from "../../../redux/slices/MessageSlice";
import ChatSkelton from "../../../components/common/ChatSkelton";
import { Stack, Button, Form, Row, Col } from "react-bootstrap";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Toaster from "../../../components/common/Toaster";
import { useDispatch, useSelector } from "react-redux";
import ico from "../../../assets/images/favicon.png";
import SquareIcon from "@mui/icons-material/Square";
import ProjectComponent from "./ProjectComponent";
import { Alert, Box, Grid, IconButton, Snackbar } from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import axios from "axios";
import {
  handleChatFileUpload,
  updateChatFileSlice,
} from "../../../redux/slices/ChatFileSlice";
import FileDrawer from "./FileDrawer";
const MarkdownViewer = lazy(() =>
  import("../../../components/chat/MarkdownViewer")
);

function Chat() {
  const chatBlock = useRef();
  const dispatch = useDispatch();
  const [chat, setChat] = useState([]);
  const [input, setInput] = useState("");
  const [stop, setStop] = useState(false);
  const [rows, setRows] = useState(1);
  const [height, setHeight] = useState("");
  const [spinner, setSpinner] = useState(false);
  const user = useSelector((state) => state.AuthSlice);
  const [rememberChat, setRememberChat] = useState([]);
  const [pcontinue, setPContinue] = useState("continue");
  const { msg } = useSelector((state) => state.MessageSlice);
  const [controller, setController] = useState(new AbortController());
  const { data: project } = useSelector((state) => state.ChatProjectSlice);
  const { data: chatdata, loading } = useSelector(
    (state) => state.ChatPromptSlice
  );

  const { loading: chatFileLoading, data: ChatFile } = useSelector(
    (state) => state.ChatFileSlice
  );

  const { currentplan } = useSelector((state) => state.AuthSlice);

  const [currentPlanPackages, setCurrentPlanPackages] = useState([]);
  const [currentPlansStatus, setCurrentPlansStatus] = useState([]);
  // console.log("currentplan.../", currentplan);

  const [open, setOpen] = useState(false);

  // console.log("currentPlansStatus.../", currentPlansStatus);
  // console.log("currentPlanPackages.../", currentPlanPackages);

  useEffect(() => {
    if (currentplan) {
      const plans = [];
      let statuses = {};
      currentplan?.map((pack) => {
        statuses = { ...statuses, [pack?.plankey]: pack?.status };
        plans.push(pack?.plankey);
      });
      setCurrentPlansStatus(statuses);
      setCurrentPlanPackages(plans);
    }
  }, [currentplan]);

  const handleStopStream = (e) => {
    setStop(false);
    controller.abort();
    setController(new AbortController());
  };

  const handleCopy = (content) => {
    content
      ? toast.success("Content copied to clipboard!")
      : toast.error("Content not found !");
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollChat = () => {
    setTimeout(() => {
      const objDiv = chatBlock.current;
      if (objDiv) {
        objDiv.scrollTo({
          top: objDiv.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      }
    });
  };

  const handleSend = async (e, chatcontinue = null) => {
    e.preventDefault();
    let currentThreadId = ChatFile?.currentThreadId || null;

    setInput("");
    setSpinner(true);
    setChat([
      ...chat,
      {
        role: "user",
        prompt: chatcontinue === "continue" ? chatcontinue : input,
        chatFile: {
          file: Object.keys(ChatFile).length > 0 ? true : false,
          ChatFile,
        },
      },
    ]);
    try {
      scrollChat();
      const message = JSON.stringify({
        messages: [
          ...rememberChat,
          {
            role: "user",
            content: chatcontinue === "continue" ? chatcontinue : input,
          },
        ],
        que: input,
        ChatFile,
        threadId: currentThreadId,
      });
      const response = await fetch(`${BASEURL}/user/chats`, {
        method: "POST",
        signal: controller.signal,
        headers: headers(),
        body: message,
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        toast.error(errorResponse.error || "Something went wrong");
        setSpinner(false);
        return;
      }
      console.log("response.headers...", response.headers);
      if (!currentThreadId) {
        const newThreadId = response.headers.get("X-Thread-Id"); // Example header
        console.log("newThreadId.../", newThreadId);
        if (newThreadId) {
          dispatch(
            updateChatFileSlice({ ...ChatFile, currentThreadId: newThreadId })
          );

          // localStorage.setItem('threadId', newThreadId); // Store in localStorage
        }
      }

      const readData = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      setSpinner(false);
      setPContinue("");
      setStop(true);
      let aiRes = "";
      while (true) {
        scrollChat();
        const { done, value } = await readData.read();
        if (done) break;
        aiRes += value;
        setChat([
          ...chat,
          {
            role: "user",
            prompt: input,
            chatFile: {
              file: Object.keys(ChatFile).length > 0 ? true : false,
              ChatFile,
            },
          },
          { airole: "assistant", airesponse: aiRes },
        ]);
        scrollChat();
      }
      setStop(false);
      setPContinue("continue");
      if (aiRes.length > 10) {
        setTimeout(() => {
          const saveprompt = {
            prompt: input,
            type: "chat",
            airesponse: aiRes,
            project: project._id,
            chatFile: {
              file: Object.keys(ChatFile).length > 0 ? true : false,
              ChatFile,
            },
          };
          setSpinner(false);
          saveHistory(saveprompt);
          dispatch(WordCountRequest());
        }, 200);
      }
    } catch (err) {
      checktoken(err);
      setSpinner(false);
      setPContinue("");
      if (err?.response?.data?.error) {
        toast.error(err.response.data.error);
      }
    }
  };

  const saveHistory = (data) => {
    axios
      .post(`${BASEURL}/user/create/chat/history`, data, { headers: headers() })
      .then((res) => {
        if (res.data.success) {
          setChat(res.data.data);
          const continueContent =
            res.data.data[res.data.data.length - 1]?.airesponse;
          const arr = [];
          res.data.data.filter((x) => {
            if (x.type === "chat" && x.prompt !== "") {
              arr.push(
                { role: x.role, content: x.prompt },
                { role: x.airole, content: x.airesponse }
              );
            }
          });
          setRememberChat([...arr.slice(Math.max(arr.length - 5, 1))]);
          setPContinue(continueContent);
        }
        scrollChat();
      })
      .catch((err) => {
        checktoken(err);
        if (err?.response?.data?.error) {
          toast.error(err.response.data.error);
        }
      });
  };

  useMemo(() => {
    if (chatdata?.length > 0) {
      const arr = [];
      chatdata.filter((x) => {
        if (x.type === "chat" && x.prompt !== "") {
          arr.push(
            { role: x.role, content: x.prompt },
            { role: x.airole, content: x.airesponse }
          );
        }
      });
      setRememberChat([...arr.slice(Math.max(arr.length - 5, 1))]);
      setChat(chatdata);
      const continueContent = chatdata[chatdata.length - 1]?.airesponse;
      setPContinue(continueContent);
      scrollChat();
    } else {
      setChat([]);
    }
    return chat;
  }, [chatdata]);

  useEffect(() => {
    setRememberChat([]);
    setHeight(document.getElementById("chat-layout").offsetHeight);
    if (user?.currentplan) {
      dispatch(WordCountRequest());
      if (project?._id) {
        dispatch(ChatPromptRequest({ id: project._id, type: "chat" }));
      }
    }
  }, [project]);

  useEffect(() => {
    if (msg) {
      toast.success(msg);
      dispatch(removeMessage({}));
    }
    setHeight(document.getElementById("chat-layout").offsetHeight);
    if (user?.currentplan) {
      dispatch(WordCountRequest());
      if (project?._id) {
        dispatch(ChatPromptRequest({ id: project._id, type: "chat" }));
      }
    }
  }, []);

  useEffect(() => {
    // console.log("input.length.../", input.length);
    // console.log("rows../", rows);
    if (input.length >= 0 && input.length < 120) setRows(1);
    else if (input.length >= 120 && input.length < 240) setRows(2);
    else if (input.length >= 240 && input.length < 360) setRows(3);
    else if (input.length >= 360 && input.length < 480) setRows(4);
    else if (input.length >= 480 && input.length < 600) setRows(5);
    else if (input.length > 600) setRows(6);
  }, [input]);

  return (
    <div
      className="flex flex-col"
      style={{ height: `calc(100vh - ${height}px)` }}
    >
      {chatFileLoading && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          autoHideDuration={180000}
          key={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity="info" variant="filled" sx={{ width: "100%" }}>
            Files are uploading by the system and it may take few minutes,
            please be patient.
          </Alert>
        </Snackbar>
      )}

      {user?.currentplan && <ProjectComponent />}
      <FileDrawer open={open} toggleDrawer={toggleDrawer} />

      <Toaster />
      <div
        id="chat-body"
        className="grow chat-block overflow-y-auto h-full p-[20px]"
        ref={chatBlock}
      >
        {chat.length > 0 ? (
          <Grid
            container
            className={`hide-scroll-bar chat-section ${
              chat.length > 0 ? "flex justify-end flex-col" : ""
            }`}
          >
            {chat.map((item, index) => (
              <Fragment key={index}>
                {item.role === "user" && item.prompt !== "" && (
                  <div>
                    <Grid
                      item
                      xs={12}
                      md={10}
                      className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start question w-100`}
                    >
                      <div className="d-flex ms-auto flex-grow-1 align-items-start">
                        <div className="d-block">
                          <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2">
                            {user?.firstname?.charAt(0)}
                            {user?.lastname?.charAt(0)}
                          </span>
                        </div>
                        <div
                          className="leading-loose w-100"
                          style={{
                            whiteSpace: "break-spaces",
                            wordBreak: "break-word",
                          }}
                        >
                          <div
                            className="question"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                item.prompt
                                  .replaceAll("\\n", "")
                                  .replaceAll("<br>", "")
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                    {item?.chatFile?.file && (
                      <>
                        <Grid
                          item
                          xs={12}
                          md={10}
                          className=" mx-auto  d-flex align-items-end question w-100"
                        >
                          <div className=" flex justify-end w-full">
                            <div className="chat-response p-2 p-sm-3 mb-2 d-block">
                              <div className="flex">
                                <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2">
                                  {user?.firstname?.charAt(0)}
                                  {user?.lastname?.charAt(0)}
                                </span>
                                <p>{item?.chatFile?.ChatFile?.fileName}</p>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </>
                    )}
                  </div>
                )}
                {item.airole === "assistant" && (
                  <Grid
                    item
                    xs={12}
                    md={10}
                    className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start w-100 answer`}
                  >
                    <div className="d-block">
                      <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2 p-1">
                        <img className="w-100 h-100" src={ico} alt="ico" />
                      </span>
                    </div>
                    <div
                      className="d-block flex-grow-1 answer-div"
                      style={{ width: "calc(100% - 43px)" }}
                    >
                      <div
                        className="leading-loose"
                        style={{ fontWeight: 500 }}
                      >
                        <Suspense fallback={<div>Loading...</div>}>
                          <MarkdownViewer
                            content={DOMPurify.sanitize(item.airesponse)
                              .replaceAll("GPT-3", "GPT-4")
                              .replaceAll("gpt-3", "GPT-4")}
                          />
                          {/* <div className="answer-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.airesponse).replaceAll("GPT-3", "GPT-4").replaceAll("gpt-3", "GPT-4") }} /> */}
                        </Suspense>
                      </div>
                      {item?._id && (
                        <div className="d-flex align-items-center justify-content-end mt-1">
                          <CopyToClipboard text={item.airesponse}>
                            <IconButton
                              onClick={() => handleCopy(item.airesponse)}
                            >
                              <ContentCopyIcon fontSize="inherit" />
                            </IconButton>
                          </CopyToClipboard>
                        </div>
                      )}
                    </div>
                  </Grid>
                )}
              </Fragment>
            ))}
          </Grid>
        ) : loading ? (
          <ChatSkelton />
        ) : (
          <div className="h-100">
            <Stack className="h-100">
              <div className="text-center col">
                <div className="h-100 d-grid" style={{ placeItems: "center" }}>
                  <div>
                    <h1>Executives GPT4o</h1>
                    <h2 className="mt-3">How can I help you today?</h2>
                  </div>
                </div>
              </div>
              {/* <Row className="m-0">
                <Col md={6} className="mb-2">
                  <div className="prompt border p-2 rounded-1 position-relative">
                    <h6>Sustainability Strategies</h6>
                    <p className="system-prompt">
                      What are the latest trends in corporate sustainability,
                      and how can I implement them to improve my business's
                      environmental impact while maintaining profitability?
                    </p>
                    <Button
                      type="button"
                      onClick={() =>
                        setInput(
                          "What are the latest trends in corporate sustainability, and how can I implement them to improve my business's environmental impact while maintaining profitability?"
                        )
                      }
                      className="up-arrow-btn chat-btn"
                    >
                      <ArrowUpwardRoundedIcon />
                    </Button>
                  </div>
                </Col>
                <Col md={6} className="mb-2">
                  <div className="prompt border p-2 rounded-1 position-relative">
                    <h6>Remote Work Optimization</h6>
                    <p className="system-prompt">
                      Can you provide strategies for optimizing productivity and
                      collaboration in a remote work environment? What tools and
                      practices should I consider to enhance my team's
                      performance?
                    </p>
                    <Button
                      type="button"
                      onClick={() =>
                        setInput(
                          "Can you provide strategies for optimizing productivity and collaboration in a remote work environment? What tools and practices should I consider to enhance my team's performance?"
                        )
                      }
                      className="up-arrow-btn chat-btn"
                    >
                      <ArrowUpwardRoundedIcon />
                    </Button>
                  </div>
                </Col>
                <Col md={6} className="mb-2">
                  <div className="prompt border p-2 rounded-1 position-relative">
                    <h6>Digital Transformation</h6>
                    <p className="system-prompt">
                      What are the essential steps for a successful digital
                      transformation in a small to medium-sized enterprise? How
                      do I ensure that technology adoption drives improvement in
                      customer service and operational efficiency?
                    </p>
                    <Button
                      type="button"
                      onClick={() =>
                        setInput(
                          "What are the essential steps for a successful digital transformation in a small to medium-sized enterprise? How do I ensure that technology adoption drives improvement in customer service and operational efficiency?"
                        )
                      }
                      className="up-arrow-btn chat-btn"
                    >
                      <ArrowUpwardRoundedIcon />
                    </Button>
                  </div>
                </Col>
                <Col md={6} className="mb-2">
                  <div className="prompt border p-2 rounded-1 position-relative">
                    <h6>Export Strategies</h6>
                    <p className="system-prompt">
                      What are the key steps to becoming a successful exporter?
                      How can I develop an export-oriented business model that
                      aligns with global market demands and compliance
                      standards?
                    </p>
                    <Button
                      type="button"
                      onClick={() =>
                        setInput(
                          "What are the key steps to becoming a successful exporter? How can I develop an export-oriented business model that aligns with global market demands and compliance standards?"
                        )
                      }
                      className="up-arrow-btn chat-btn"
                    >
                      <ArrowUpwardRoundedIcon />
                    </Button>
                  </div>
                </Col>
              </Row> */}
            </Stack>
          </div>
        )}
      </div>
      {
        // user?.currentplan?.[0]
        (currentPlanPackages.includes("basic_monthly") ||
          currentPlanPackages.includes("basic_semianual") ||
          currentPlanPackages.includes("pro_monthly") ||
          currentPlanPackages.includes("pro_semianual")) && (
          <Grid
            container
            className="chat-actions sticky bottom-0 bg-white px-[20px] pb-[12px]"
            style={{ zIndex: "2" }}
          >
            <Grid
              item
              xs={12}
              md={10}
              className="chat-input-group chat-input-group-custom mx-auto"
            >
              <Form
                onKeyDown={(e) =>
                  e.key === "Enter" && !e.shiftKey ? e.preventDefault() : null
                }
              >
                <Form.Group className="position-relative">
                  <Form.Control
                    placeholder="Type your test message here..."
                    onChange={(e) => setInput(e.target.value)}
                    onKeyUp={(e) =>
                      e.key === "Enter" &&
                      !e.shiftKey &&
                      e.target.value.trim() !== ""
                        ? handleSend(e)
                        : null
                    }
                    value={input}
                    as="textarea"
                    rows={rows}
                  />
                  <div className="chat-btns">
                    {input.length < 4 ? (
                      <Button type="button" disabled className="ochat-btn">
                        {spinner ? (
                          <ButtonSpinner />
                        ) : (
                          <ArrowUpwardRoundedIcon />
                        )}
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="ochat-btn"
                        onClick={(e) => handleSend(e)}
                      >
                        {spinner ? (
                          <ButtonSpinner />
                        ) : (
                          <ArrowUpwardRoundedIcon />
                        )}
                      </Button>
                    )}
                    <CustomTooltip
                      title="Upload Files"
                      arrow
                      placement="top"
                      content={
                        <Button
                          type="button"
                          className="ms-2 ochat-btn position-relative"
                        >
                          {chatFileLoading ? (
                            <ButtonSpinner />
                          ) : (
                            <AttachmentIcon />
                          )}
                          <input
                            type="file"
                            onChange={(e) => {
                              dispatch(
                                handleChatFileUpload({
                                  files: e.target.files,
                                  id: project?._id,
                                })
                              );
                              e.target.value = "";
                            }}
                            multiple
                            className="opacity-0 position-absolute w-100 top-0 bottom-0 start-0 end-0"
                          />
                        </Button>
                      }
                    />

                    {/*                    <CustomTooltip
                      title="View Files"
                      arrow
                      placement="top"
                      content={
                        <Button
                          type="button"
                          className="ms-2 ochat-btn"
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          {" "}
                          <FilePresentIcon />
                        </Button>
                      }
                    />
 */}
                    {stop && (
                      <Button
                        onClick={handleStopStream}
                        type="button"
                        className="ms-2 ochat-btn"
                      >
                        <SquareIcon />
                      </Button>
                    )}
                    {pcontinue && (
                      <CustomTooltip
                        title="Continue"
                        arrow
                        placement="top"
                        content={
                          <Button
                            onClick={(e) => {
                              setPContinue("continue");
                              handleSend(e, "continue");
                            }}
                            className="ms-2 ochat-btn"
                          >
                            <ArrowRightIcon />
                          </Button>
                        }
                      />
                    )}
                  </div>
                </Form.Group>
              </Form>
            </Grid>
          </Grid>
        )
      }
    </div>
  );
}

export default Chat;
